<template>
  <dashboard-wrapper>
    <div class="loading-template" v-if="loading">
      <loading-indicator />
    </div>
    <div class="border-bottom">
      <div class="_flex _between flex-wrap py-4 px-6">
        <div>
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb p-0 m-0 bg-transparent tiny">
              <li class="breadcrumb-item">
                <router-link to="/dashboard">Dashboard</router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Profil
              </li>
            </ol>
          </nav>
          <h2 class="bold mt-2">Mon Profil Artiste</h2>
          <p>
            <i class="fas fa-exclamation-circle"></i> Vous devez renseigner vos
            informations avant de publier vos oeuvres sur la plateforme
          </p>
        </div>
        <button class="btn black" @click="onSubmit" :disabled="loading">
          <i class="far fa-spinner fa-pulse mr-2" v-if="loading"></i>
          Sauvegarder
        </button>
      </div>
    </div>

    <fieldset :disabled="loading" class="py-8 px-6">
      <div class="form-row">
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <label>Quels sont vos métiers ?</label>
            <p class="body-1 text-primary">
              Vous pouvez sélectionner plusieurs métiers
            </p>
            <multiselect v-model="dataForm.categories_ids" label="name" track-by="category_id" :options="allCategories"
              placeholder="Choisir" :multiple="true" :taggable="true" :allow-empty="false"
              :searchable="false" selectLabel="Choisir" deselectLabel="Supprimer" :limit="10">
            </multiselect>
          </div>
        </div>
        <div class="col-lg-4"></div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <label>Photo profil</label>
            <div class="image_box bg-light c-pointer" style="max-width:180px" @click="onPickPicture">
              <img :src="pictureUrl" alt="" v-if="pictureUrl" style="object-fit: fill"/>
              <div class="normal">
                <i class="fas fa-image display-4 text-muted"></i>
              </div>
              <input @change="onPicturePicked" ref="fileInput" type="file" style="display: none;" accept="image/*"/>
            </div>
          </div>
        </div>
        <div class="col-12"></div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <label>Nom</label>
            <input v-model.trim="dataForm.first_name" type="text" class="form-control rounded-0" required autocomplete="none"/>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <label>Post-nom</label>
            <input v-model.trim="dataForm.middle_name" type="text" class="form-control rounded-0" autocomplete="none"/>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <label>Prénom</label>
            <input v-model.trim="dataForm.last_name" type="text" class="form-control rounded-0" required autocomplete="none"/>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <label>Email</label>
            <input v-model.trim="dataForm.email" type="text" class="form-control rounded-0" required autocomplete="none"/>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <label>Téléphone</label>
            <input v-model.trim="dataForm.phone" class="form-control rounded-0" type="text"/>
          </div>
        </div>
        <div class="col-12 col-md-4 col-lg-3 col-xl-2">
          <div class="form-group">
            <label>Date de naissance</label>
            <input v-model.trim="dataForm.birthday" type="date" class="form-control rounded-0" required autocomplete="none">
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <label>Pays</label>
            <select v-model="dataForm.country" class="form-control">
              <option :value="country.name_fr" v-for="country in getCountries" :key="country.id">
                {{ country.name_fr.charAt(0).toUpperCase() + country.name_fr.substring(1).toLowerCase() }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <label>Ville</label>
            <input v-model.trim="dataForm.city" type="text" class="form-control rounded-0" required autocomplete="none"/>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label>Adresse</label>
            <textarea v-model.trim="dataForm.address" class="form-control rounded-0" cols="8" rows="2" required autocomplete="none"></textarea>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label>A propos de vous</label>
            <p class="body-1 text-primary">
              <i class="far fa-exclamation-circle"></i>&nbsp;&nbsp;Ce champ vous permet de vous décrire, sur votre profession et toutes vos réalisations
            </p>
            <textarea v-model="dataForm.apropos" rows="3" class="form-control"></textarea>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label>Etiquettes</label>
            <p class="body-1 text-primary">
              <i class="far fa-exclamation-circle"></i>&nbsp;&nbsp;Séparer chaque étiquette par une virgule
            </p>
            <div class="chip-container">
<!--              <div class="chip" v-for="(chip, i) of tags" :key="chip.label">
                {{ chip }}
                <i class="fal fa-times" @click=""></i>
              </div>-->
              <input v-model="dataForm.etiquettes" @keypress.enter="" @keydown.delete=""/>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="pl-md-4">
        <button class="btn black" @click="onSubmit" :disabled="loading">
          <i class="far fa-spinner fa-pulse mr-2" v-if="loading"></i> Sauvegarder
        </button>
      </div>
    </fieldset>
  </dashboard-wrapper>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Multiselect from "vue-multiselect";

export default {
  components: {
    multiselect: Multiselect,
  },
  props: {
    set: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    editor: ClassicEditor,
    editorConfig: {
      toolbar: ["bold", "italic", "|", "link"],
    },
    dataForm: {
      first_name: "",
      middle_name: "",
      last_name: "",
      email: "",
      phone: "",
      country: "",
      city: "",
      avatar: "",
      password: "",
      new_password: "",
      type_user: "",
      status: "",
      address: "",
      birthday: "",
      apropos: "",
      etiquettes: [],
      categories_ids: [],
      table: "mise_a_jour",
      table_file: "addPicture",
      table_id: ""
    },
    allCategories: [],
    //
    diploma: "",
    pictureUrl: "",
    picture: null,
    //
    loading: false,
  }),
  computed: {
    ...mapGetters([
      "user",
      "profile",
      "countries",
      "error",
      "getUserData",
      "getToken",
      "getCountries"
    ]),
  },
  watch: {
    error(value) {
      if (value) {
        window.scrollTo(0, 0);
      }
    },
    getUserData(value) {
      if (value && value.userData) {
        this.setDefaultValues(value.userData)
      }
    },
  },
  methods: {
    ...mapMutations([]),
    ...mapActions([
      "tryAutoLogin",
      "axiosGetAllData",
      "axiosPutData",
      "axiosPostFileData",
    ]),
    onPickPicture() {
      this.$refs.fileInput.click();
    },
    onPicturePicked(e) {
      let pictureUploaded = e.target.files[0]
      if(pictureUploaded){
        let sizePictureMb = (pictureUploaded.size/1000000).toFixed(2)
        // let sizePictureKb = (pictureUploaded.size/1000).toFixed(2)
        if(sizePictureMb > 2){
          this.$toast.warning("Veuillez svp choisir une image dont la taille ne dépasse pas 2Mb !", {
            icon: "fas fa-2x fa-exclamation",
          });
        }else {
          this.pictureUrl = URL.createObjectURL(pictureUploaded);
          this.picture = pictureUploaded;
        }
      }
    },
    onUploadPicture(){
      if(this.picture){
        let formData = new FormData()
        formData.append('file_uploaded', this.picture)
        formData.append('user_id', this.getToken)
        formData.append('category_file', 'users')
        this.axiosPostFileData({data: formData, dataForm: this.dataForm})
            .then(response => {
              if(response.status === 200){
                this.tryAutoLogin()
                this.$toast.success(response.data.message, {
                  icon: "fas fa-2x fa-check-circle",
                });
              }else {
                this.$toast.error(response.data.message, {
                  icon: "fas fa-2x fa-times-circle",
                });
              }
            })
            .catch(error => {
              this.loading = false
            })
      }
    },
    onSubmit() {
      this.loading = true;
      let categories_ids = []
      if(this.dataForm.categories_ids && this.dataForm.categories_ids.length > 0){
        this.dataForm.categories_ids.filter(
            (category) => {
              categories_ids.push(category.category_id)
            })
      }
      this.resetDefaultValues()
      this.dataForm.categories_ids = categories_ids.join()
      this.axiosPutData(this.dataForm)
          .then( response => {
            if(response.status === 200){
              this.tryAutoLogin()
              this.$toast.success(response.data.message, {
                icon: "fas fa-2x fa-check-circle",
              });
              this.onUploadPicture()
            }else {
              this.$toast.error(response.data.message, {
                icon: "fas fa-2x fa-times-circle",
              });
            }
            this.loading = false;
          })
          .catch()
    },
    resetDefaultValues() {
      if(this.dataForm.first_name === '')
        this.dataForm.first_name = this.getUserData.userData.first_name
      if(this.dataForm.middle_name === '')
        this.dataForm.middle_name = this.getUserData.userData.middle_name
      if(this.dataForm.last_name === '')
        this.dataForm.last_name = this.getUserData.userData.last_name
      if(this.dataForm.email === '')
        this.dataForm.email = this.getUserData.userData.email
      if(this.dataForm.phone === '')
        this.dataForm.phone = this.getUserData.userData.phone
      if(this.dataForm.country === '')
        this.dataForm.country = this.getUserData.userData.country
      if(this.dataForm.city === '')
        this.dataForm.city = this.getUserData.userData.city
      if(this.dataForm.avatar === '')
        this.dataForm.avatar = this.getUserData.userData.avatar
      if(this.dataForm.new_password !== '')
        this.dataForm.password = this.dataForm.new_password
      if(this.dataForm.type_user === '')
        this.dataForm.type_user = this.getUserData.userData.type_user
      if(this.dataForm.status === '')
        this.dataForm.status = this.getUserData.userData.status
      if(this.dataForm.address === '')
        this.dataForm.address = this.getUserData.userData.address
      if(this.dataForm.birthday === '')
        this.dataForm.birthday = this.getUserData.userData.birthday
      if(this.dataForm.apropos === '')
        this.dataForm.apropos = this.getUserData.userData.apropos
      if(this.dataForm.etiquettes === '')
        this.dataForm.etiquettes = this.getUserData.userData.etiquettes
      if(this.dataForm.categories_ids === '')
        this.dataForm.categories_ids = this.getUserData.userData.categories_ids
    },
    setDefaultValues(data) {
      this.dataForm.first_name = data.first_name
      this.dataForm.middle_name = data.middle_name
      this.dataForm.last_name = data.last_name
      this.dataForm.email = data.email
      this.dataForm.phone = data.phone
      this.dataForm.country = data.country
      this.dataForm.city = data.city
      this.dataForm.avatar = data.avatar
      this.dataForm.type_user = data.type_user
      this.dataForm.status = data.status
      this.dataForm.address = data.address
      this.dataForm.birthday = data.birthday
      this.dataForm.apropos = data.apropos
      this.dataForm.etiquettes = data.etiquettes
      this.dataForm.categories_ids = data.categories_ids
    },
  },
  mounted() {},
  created() {
    this.tryAutoLogin()
    this.axiosGetAllData({table: "retrieveCategories"})
        .then( response => {
          if(response.status === 200){
            this.allCategories = response.data.data
          }
          this.loading = false;
        })
        .catch()
    this.dataForm.table_id = this.getToken
    this.pictureUrl = this.getUserData.userData.picture_data.src
  },
};
</script>
